<template>
  <div
    class="app-background"
    :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <div v-if="!hasOrganizations">
      <div class="auth-form">
        <img alt="logo" class="logo" :src="logo" />
        <h3 class="heading">Admin Sign In</h3>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          "
          class="google-login"
        >
          <GoogleLogin :callback="adminCallback" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters, useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const rules = {
      email: [
        {
          required: true,
          type: 'email',
          trigger: 'submit',
          message: 'email is required',
        },
      ],
      password: [
        { required: true, trigger: 'submit', message: 'password is required' },
      ],
    };

    const toast = useToast();

    return {
      rules,
      logo: require('src/assets/img/assembly-cp-logo.png'),
      backgroundImage: require('src/assets/img/backgroundImage.png'),
      store,
      toast,
    };
  },
  data() {
    return {
      showVerifyRoute: false,
      account: undefined,
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters(['accessToken', 'userOrganizations', 'email', 'role']),
    hasOrganizations() {
      return this.userOrganizations && this.userOrganizations.length > 0;
    },
  },
  watch: {
    userOrganizations: {
      deep: true,
      handler() {
        this.OTPModal = false;
      },
    },
  },
  methods: {
    ...mapActions(['adminSignIn', 'userLogout', 'clearState']),
    adminCallback(response) {
      const payload = {
        access_token: response.credential,
      };
      this.adminSignIn(payload);
    },
  },
};
</script>

<style scoped>
/* Your existing styles... */

.container {
  height: 100vh;
  overflow: hidden;
}

.auth-form {
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  opacity: 0.9;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}
.ms-logo {
  height: 15px;
  margin-bottom: 3px;
  margin-right: 8px;
  align-content: center;
}
.signin-btn {
  margin-left: 0px;
}
.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.user-form {
  width: 80%;
  margin-top: 20px;
}

.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
.middle {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.continue {
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #707a8a;
}
.divider {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  height: 1px;
  flex: 1;
  background-color: #eaecef;
}

.app-background {
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}

.app-background::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.app-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}
.organization-card {
  width: 450px;
  margin: auto;
  text-align: center;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  padding: 20px 40px;
  opacity: 0.95; /* Slightly more opaque */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effect */
}

.organization-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px; /* Darker shadow on hover */
}

.heading {
  font-size: 1.2rem; /* Reduced font size for the heading */
  margin-bottom: 20px; /* Space between heading and list */
}

.organization-list {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  max-height: 400px; /* Set a max height for the list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
  overflow-x: hidden; /* Hide horizontal scrollbar if it appears */
  border-radius: 8px; /* Rounded corners for list */
  padding-right: 10px; /* Space for scrollbar */
}

.organization-list li {
  background-color: #f0f4f8; /* Light background for list items */
  border-radius: 8px; /* Rounded corners */
  padding: 12px 20px; /* Increased padding for comfort */
  margin: 10px 0; /* Space between items */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effect */
  cursor: pointer; /* Change cursor on hover */
  font-size: 1.1rem; /* Slightly larger font for better readability */
}

.organization-list li:hover {
  background-color: #e0e7ee; /* Darker shade on hover */
  transform: scale(1.02); /* Slight scale effect on hover */
}
</style>
